import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

function App() {
	return (
		<div className="App">
			<div className="container">
				<h1>Equity Companion</h1>
				<h2>Hassle-Free Mortgages</h2>
			</div>
			<div className="contact">
				<a href="tel:+16478872623">
					<FontAwesomeIcon icon={faPhone} /> Call
				</a>
				<a href="mailto:manjit@equitycompanion.ca">
					<FontAwesomeIcon icon={faEnvelope} /> E-Mail
				</a>
			</div>
		</div>
	);
}

export default App;
